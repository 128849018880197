import { Row as AntRow, Table, TableProps, Image, Button } from 'antd';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useContext, useEffect, useState } from 'react';
import { MessageContext } from '../../context/messageContext';
import { IHighlight } from '../../types';
import {
  createHighlight,
  updateHighlight,
  deleteHighlight,
  getAllHighlights,
  reorderHighlights,
  uploadImage,
  uploadVideo,
} from '../../utils/api';
import parseErrors from '../../utils/parseErrors';
import { useDispatch } from 'react-redux';
import React from 'react';
import { setLoader } from '../../store/actions/mainActions';
import CreateHighlight from './CreateHighlight';
import { DeleteOutlined, EditOutlined, MenuOutlined } from '@ant-design/icons';
import EditHighlight from './EditHighlight';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined ref={setActivatorNodeRef} style={{ touchAction: 'none', cursor: 'move' }} {...listeners} />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const HighlightsTable = () => {
  const dispatch = useDispatch();
  const [showEdit, setShowEdit] = useState(false);
  const [editingHighlight, setEditingHighlight] = useState<IHighlight>();
  const [dataSource, setDataSource] = useState<IHighlight[]>([]);
  //const [dataSource, setDataSource] = useState<IHighlight[]>([]);
  const messageContext = useContext(MessageContext);

  useEffect(() => {
    fetchHighlights();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteHighlight = async (id: string) => {
    dispatch(setLoader(true));
    try {
      await deleteHighlight(id);
      await fetchHighlights();
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }
    dispatch(setLoader(false));
  };

  const handleCreateHighlight = async (payload: any) => {
    dispatch(setLoader(true));
    try {
      if (payload.file) {
        const response = await uploadImage(payload.file, 'Highlights');
        payload.backgroundImageUrl = response.data.data.imageUrl;
        delete payload.file;
      }
      if (payload.video) {
        const response = await uploadVideo(payload.video, 'videos');
        payload.uploadedVideoUrl = response.data.data.videoUrl;
        delete payload.video;
      }

      await createHighlight(payload);
      await fetchHighlights();
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }
    dispatch(setLoader(false));
  };

  const handleUpdateHighlight = async (id: string, payload: any) => {
    dispatch(setLoader(true));
    try {
      if (payload.file) {
        const response = await uploadImage(payload.file, 'Highlights');
        payload.backgroundImageUrl = response.data.data.imageUrl;
        delete payload.file;
      }
      if (payload.video) {
        const response = await uploadVideo(payload.video, 'videos');
        payload.uploadedVideoUrl = response.data.data.videoUrl;
        delete payload.video;
      }

      await updateHighlight(id, payload);
      await fetchHighlights();
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }
    dispatch(setLoader(false));
  };

  const fetchHighlights = async () => {
    dispatch(setLoader(true));

    try {
      const highlights = await getAllHighlights();
      console.log('highlights', highlights);
      setDataSource(highlights.data.data);
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.id === active.id);
        const overIndex = previous.findIndex((i) => i.id === over?.id);
        const updatedDataSource = arrayMove(previous, activeIndex, overIndex);
        updateOrdering(updatedDataSource.map((h) => h.id));
        return updatedDataSource;
      });
    }
  };

  const updateOrdering = async (orderingIds: string[]) => {
    dispatch(setLoader(true));
    await reorderHighlights(orderingIds);
    // const updatedCuratedList = await getCuratedList(curatedList.id);
    // setDataSource(updatedCuratedList.data.data.hypelists);
    dispatch(setLoader(false));
  };

  const columns: TableProps<IHighlight>['columns'] = [
    {
      key: 'sort',
    },
    {
      title: 'Cover',
      dataIndex: 'backgroundImageUrl',
      key: 'backgroundImageUrl',
      render: (backgroundImageUrl) => <Image width={65} src={backgroundImageUrl || ''} />,
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <div>{id}</div>,
    },
    {
      title: 'Title',
      dataIndex: 'highlightTitle',
      key: 'highlightTitle',
      render: (text) => <div>{text}</div>,
    },
    {
      title: 'Subtitle',
      dataIndex: 'highlightSubtitle',
      key: 'highlightSubtitle',
      render: (text) => <div>{text}</div>,
    },
    {
      title: 'HLS Video URL',
      dataIndex: 'hlsVideoUrl',
      key: 'hlsVideoUrl',
      render: (text) => <div>{text}</div>,
    },
    {
      title: 'External URL',
      dataIndex: 'externalLinkUrl',
      key: 'externalLinkUrl',
      render: (text) => <div>{text}</div>,
    },
    {
      title: 'External URL Text',
      dataIndex: 'externalLinkTitle',
      key: 'externalLinkTitle',
      render: (text) => <div>{text}</div>,
    },
    {
      title: 'Linked Hypelist',
      dataIndex: 'hypelist',
      key: 'hypelist',
      render: (hypelist) => <div>{hypelist && hypelist.title}</div>,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <AntRow>
          <Button type="primary" danger onClick={() => handleDeleteHighlight(record.id)}>
            <DeleteOutlined />
          </Button>
          <Button
            type="primary"
            onClick={() => {
              setEditingHighlight(record);
              setShowEdit(true);
            }}
          >
            <EditOutlined />
          </Button>
        </AntRow>
      ),
    },
  ];
  return (
    <>
      <AntRow>
        <h2>Highlights</h2>&nbsp;
        <CreateHighlight handleCreateHighlight={handleCreateHighlight} />
        {editingHighlight && showEdit && (
          <EditHighlight
            highlightData={editingHighlight}
            handleEditHighlight={handleUpdateHighlight}
            setShowModal={setShowEdit}
            showModal={showEdit}
          />
        )}
      </AntRow>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dataSource.map((i) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            rowKey="id"
            components={{
              body: {
                row: Row,
              },
            }}
            columns={columns}
            dataSource={dataSource}
            size="small"
          />
        </SortableContext>
      </DndContext>
    </>
  );
};

export default HighlightsTable;
