import { LeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Popconfirm, Row, Tooltip } from 'antd';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { MessageContext } from '../../context/messageContext';
import { setLoader } from '../../store/actions/mainActions';
import { IUpdateAdmin } from '../../types';
import { deleteAdmin, getAdminById } from '../../utils/api';
import parseErrors from '../../utils/parseErrors';

const Admin = () => {
  const { userUid } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const messageContext = useContext(MessageContext);
  const [adminData, setAdminData] = useState<IUpdateAdmin>({
    username: '',
    email: '',
  });
  const [form] = Form.useForm();

  useEffect(() => {
    fetchAdmin();
  }, [userUid]);

  const fetchAdmin = async () => {
    dispatch(setLoader(true));

    try {
      const admin = await getAdminById(userUid ?? '');
      setAdminData({
        username: admin.data.data.username,
        email: admin.data.data.email,
      });
      form.setFieldsValue(admin.data.data);
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  const onChange = (name: string) => (event: ChangeEvent<HTMLInputElement>) => {
    setAdminData({
      ...adminData,
      [name]: event.target.value,
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleDeleteAdmin = async () => {
    dispatch(setLoader(true));

    try {
      await deleteAdmin(userUid ?? '');
      goBack();
    } catch (error: any) {
      console.log(error);
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  return (
    <div className="admin">
      <Row className="title">
        <Tooltip title="Back">
          <Button onClick={goBack} icon={<LeftOutlined />} />
        </Tooltip>

        <h1>{adminData.username}</h1>
      </Row>

      <Row>
        <Form
          initialValues={adminData}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          name="control-hooks"
        >
          <Form.Item name="username" label="Username">
            <Input value={adminData.username} onChange={onChange('username')} disabled />
          </Form.Item>

          <Form.Item name="email" label="Email" rules={[{ type: 'email' }]}>
            <Input value={adminData.email} onChange={onChange('email')} disabled />
          </Form.Item>

          <Form.Item className="btn-container" wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" disabled>
              Save
            </Button>
            <Popconfirm
              title="Delete admin"
              description="Are you sure to delete admin?"
              onConfirm={handleDeleteAdmin}
              okText="Delete"
              cancelText="Cancel"
            >
              <Button danger>Delete</Button>
            </Popconfirm>
          </Form.Item>
        </Form>
      </Row>
    </div>
  );
};

export default Admin;
