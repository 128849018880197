import {
  DashboardOutlined,
  FileExclamationOutlined,
  IdcardOutlined,
  LogoutOutlined,
  UsergroupAddOutlined,
  SearchOutlined,
  AuditOutlined,
  RedEnvelopeOutlined,
  FilterOutlined,
  AlertOutlined,
  MessageOutlined,
  ContainerOutlined,
} from '@ant-design/icons';
import { Button, Menu, message, Tooltip } from 'antd';
import Layout, { Content, Header } from 'antd/es/layout/layout';
import Sider from 'antd/es/layout/Sider';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { FavIcon, LogoIcon } from './components/icons';
import Loader from './components/Loader';
import { MessageContext } from './context/messageContext';
import Admin from './pages/admin';
import Admins from './pages/admins';
import CreateAdmin from './pages/createAdmin';
import Dashboard from './pages/dashboard';
import Hypelist from './pages/hypelist';
import NoMatch from './pages/noMatch';
// import Notifications from "./pages/notifications";
import ReportedHypelists from './pages/reported-hypelists';
import Signin from './pages/signin';
import User from './pages/user';
import Users from './pages/users';
import { RootState } from './store';
import { setAuthenticated, setAuthResponseData } from './store/actions/authActions';
import { clearStore } from './store/actions/mainActions';
import Discover from './pages/discover';
import Hypelists from './pages/hypelists';
import Invitations from './pages/invitations';
import Exclusions from './pages/exclusions';
import CreateExclusion from './pages/createExclusion';
import ReportedUsers from './pages/reported-users';
import ChatTemplates from './pages/chatTemplates';
import CreateChatTemplates from './pages/createChatTemplates';
import ReportedComments from './pages/reported-comments';

const App = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.authStore.isAuthenticated);
  const isLoading = useSelector((state: RootState) => state.mainStore.isLoading);
  const location = useLocation();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const messageContext = useContext(MessageContext);
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    // Set message context instance
    messageContext.setInstance(messageApi);

    // Check local storage
    try {
      const storageIsAuthenticated = window.sessionStorage.getItem('isAuthenticated');
      const storageAuthResponse = JSON.parse(window.sessionStorage.getItem('authResponse') ?? '{}');
      if (storageIsAuthenticated) {
        dispatch(setAuthenticated(storageIsAuthenticated === 'true'));

        dispatch(setAuthResponseData(storageAuthResponse));
      }
    } catch (error) {}

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/dashboard');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const menuItems: ItemType[] = [
    {
      key: '/dashboard',
      label: 'Dashboard',
      icon: <DashboardOutlined />,
      onClick: (info: MenuInfo) => navigate(info.key),
    },
    {
      key: location.pathname.startsWith('/admin') ? location.pathname : '/admins',
      label: 'Admins',
      icon: <UsergroupAddOutlined />,
      onClick: (info: MenuInfo) => {
        if (info.key === location.pathname) {
          navigate('/admins');
          return;
        }

        navigate(info.key);
      },
    },
    {
      key: location.pathname.startsWith('/user') ? location.pathname : '/users',
      label: 'Users',
      icon: <IdcardOutlined />,
      onClick: (info: MenuInfo) => {
        if (info.key === location.pathname) {
          navigate('/users');
          return;
        }

        navigate(info.key);
      },
    },
    {
      key: location.pathname.startsWith('/hypelists') ? location.pathname : '/hypelists',
      label: 'Hypelists',
      icon: <AuditOutlined />,
      onClick: (info: MenuInfo) => {
        if (info.key === location.pathname) {
          navigate('/hypelists');
          return;
        }

        navigate(info.key);
      },
    },
    {
      key: location.pathname.startsWith('/reported-hypelists') ? location.pathname : '/reported-hypelists',
      label: 'Reported Hypelists',
      icon: <FileExclamationOutlined />,
      onClick: (info: MenuInfo) => {
        if (info.key === location.pathname) {
          navigate('/reported-hypelists');
          return;
        }

        navigate(info.key);
      },
    },
    {
      key: location.pathname.startsWith('/reported-users') ? location.pathname : '/reported-users',
      label: 'Reported Users',
      icon: <AlertOutlined />,
      onClick: (info: MenuInfo) => {
        if (info.key === location.pathname) {
          navigate('/reported-users');
          return;
        }

        navigate(info.key);
      },
    },
    {
      key: location.pathname.startsWith('/reported-comments') ? location.pathname : '/reported-comments',
      label: 'Reported Comments',
      icon: <MessageOutlined />,
      onClick: (info: MenuInfo) => {
        if (info.key === location.pathname) {
          navigate('/reported-comments');
          return;
        }

        navigate(info.key);
      },
    },
    // {
    //   key: "/notifications",
    //   label: "Notifications",
    //   icon: <NotificationOutlined />,
    //   onClick: (info: MenuInfo) => navigate(info.key),
    // },
    {
      key: '/discover',
      label: 'Discover',
      icon: <SearchOutlined />,
      onClick: (info: MenuInfo) => navigate(info.key),
    },
    {
      key: '/invitations',
      label: 'Invitations',
      icon: <RedEnvelopeOutlined />,
      onClick: (info: MenuInfo) => navigate(info.key),
    },
    {
      key: '/exclusions',
      label: 'Exclusions',
      icon: <FilterOutlined />,
      onClick: (info: MenuInfo) => navigate(info.key),
    },
    {
      key: '/templates',
      label: 'Prompt Templates',
      icon: <ContainerOutlined />,
      onClick: (info: MenuInfo) => navigate(info.key),
    },
  ];

  const switchSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const onLogout = () => {
    dispatch(clearStore());
  };

  const getCurrentActiveRoute = () => {
    const routes = [];

    if (location.pathname.startsWith('/user')) {
      routes.push('/user');
    }

    routes.push(location.pathname);

    return routes;
  };

  return (
    <Layout className="app">
      {contextHolder}
      {isLoading && <Loader />}

      {!isAuthenticated ? (
        <Signin />
      ) : (
        <>
          <Sider collapsible collapsedWidth={60} collapsed={isCollapsed} onCollapse={switchSidebar}>
            <div className="logo">
              <Link to={'/'}>
                {isCollapsed ? (
                  <div className="favicon">
                    <FavIcon />
                  </div>
                ) : (
                  <>
                    <LogoIcon color={'#fff'} />
                  </>
                )}
              </Link>
            </div>

            <Menu theme="dark" items={menuItems} selectedKeys={getCurrentActiveRoute()} />
          </Sider>
          <Layout>
            <Header className="header">
              <Tooltip title="logout">
                <Button onClick={onLogout} icon={<LogoutOutlined />} />
              </Tooltip>
            </Header>
            <Content>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/admins" element={<Admins />} />
                <Route path="/admins/:userUid" element={<Admin />} />
                <Route path="/admins/add" element={<CreateAdmin />} />
                <Route path="/users" element={<Users />} />
                <Route path="/users/:userId" element={<User />} />
                <Route path="/hypelists" element={<Hypelists />} />
                <Route path="/hypelist/:id" element={<Hypelist />} />
                <Route path="/reported-hypelists" element={<ReportedHypelists />} />
                <Route path="/reported-users" element={<ReportedUsers />} />
                <Route path="/reported-comments" element={<ReportedComments />} />
                {/* <Route path="/notifications" element={<Notifications />} /> */}
                <Route path="/discover" element={<Discover />} />
                <Route path="/invitations" element={<Invitations />} />
                <Route path="/exclusions" element={<Exclusions />} />
                <Route path="/exclusions/add" element={<CreateExclusion />} />
                <Route path="/templates" element={<ChatTemplates />} />
                <Route path="/templates/add" element={<CreateChatTemplates />} />
                <Route path="*" element={<NoMatch />} />
              </Routes>
            </Content>
          </Layout>
        </>
      )}
    </Layout>
  );
};

export default App;
