import { Card, Col, Row, Statistic } from 'antd';
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageContext } from '../../context/messageContext';
import { RootState } from '../../store';
import { setMetricsData } from '../../store/actions/dashboardActions';
import { setLoader } from '../../store/actions/mainActions';
import { getMetrics } from '../../utils/api';
import parseErrors from '../../utils/parseErrors';

const Dashboard = () => {
  const dispatch = useDispatch();
  const messageContext = useContext(MessageContext);
  const metrics = useSelector((state: RootState) => state.dashboardStore.metrics);

  useEffect(() => {
    // Get metrics data
    getMetricsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMetricsData = async () => {
    dispatch(setLoader(true));

    try {
      const metrics = await getMetrics();
      dispatch(setMetricsData(metrics.data.data));
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }

    dispatch(setLoader(false));
  };

  return (
    <div className="dashboard">
      <Row>
        <h1>Dashboard</h1>
      </Row>
      <Row gutter={16}>
        <Col span={6}>
          <Card title="Users">
            <Row>
              <Col style={{ width: 100, marginRight: '1rem' }}>
                <Statistic title="Total" value={metrics.user} />
              </Col>
              <Col style={{ width: 120 }}>
                <Statistic title="Active Subscribers" value={metrics.currentActiveSubscriptions} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Hypelists">
            <Row>
              <Col style={{ width: 100 }}>
                <Statistic title="Total" value={metrics.totalHypelists} />
              </Col>
              <Col style={{ width: 100 }}>
                <Statistic title="Public" value={metrics.publicHypelists} />
              </Col>
              <Col style={{ width: 100 }}>
                <Statistic title="Private" value={metrics.privateHypelists} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={6}>
          <Card title="Hypelist Items">
            <Statistic title="Total" value={metrics.hypelistItems} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
