import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Row, Table } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageContext } from '../../context/messageContext';
import { IAdmin } from '../../types';
import { getAdmins } from '../../utils/api';
import parseErrors from '../../utils/parseErrors';

const Admins = () => {
  const messageContext = useContext(MessageContext);
  const navigate = useNavigate();
  const [adminsList, setAdminsList] = useState<IAdmin[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const columns = [
    {
      title: 'UID',
      dataIndex: 'uid',
      key: 'uid',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    },
  ];

  useEffect(() => {
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    setIsLoading(true);

    try {
      const users = await getAdmins();
      setAdminsList(users.data.data);
    } catch (error: any) {
      const errors = parseErrors(error.response?.data?.error);

      for (let i = 0; i < errors.length; i += 1) {
        messageContext.instance?.open({
          type: 'error',
          content: errors[i],
        });
      }
    }

    setIsLoading(false);
  };

  const viewUser = (user: IAdmin) => {
    navigate(`/admins/${user.uid}`);
  };

  const addAdmin = () => {
    navigate(`/admins/add`);
  };

  return (
    <div className="admins">
      <Row>
        <h1>Admins</h1>
      </Row>

      <Row>
        <Row className="btn-container">
          <Button type="primary" onClick={addAdmin}>
            <PlusCircleOutlined />
            Add
          </Button>
        </Row>
        <Table
          onRow={(record) => {
            return {
              onClick: () => {
                viewUser(record);
              },
            };
          }}
          rowKey="uid"
          columns={columns}
          dataSource={adminsList}
          loading={isLoading}
        />
      </Row>
    </div>
  );
};

export default Admins;
